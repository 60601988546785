import React from 'react';

import { useUserWorkspacesQuery } from '@app/data/user-workspaces.query';
import { WorkspaceSwitcherComponents } from '@widgets/workspaces';
import { useUserWorkspace } from '@hooks/use-user-workspace';
import { AngleUp } from '@benepass/icons';
import { Button } from 'react-components';

const Trigger = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & { isOpen: boolean }
>((props, ref) => (
  <Button ref={ref} {...props} variant="secondary">
    Switch workspace <AngleUp className={['transition-transform', props.isOpen ? '' : 'rotate-180'].join(' ')} />
  </Button>
));

Trigger.displayName = 'SettingsWorkspaceSwitcherTrigger';

const WorkspaceSwitcher = () => {
  const workspaces = useUserWorkspacesQuery();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);
  const [userWorkspace] = useUserWorkspace();

  const selectedWorkspace = React.useMemo(
    () => workspaces.find((workspace) => userWorkspace === workspace.apiID),
    [workspaces, userWorkspace]
  );

  if ((workspaces || []).length <= 1) {
    return null;
  }

  return (
    <WorkspaceSwitcherComponents.Root isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen}>
      <WorkspaceSwitcherComponents.Trigger>
        <Trigger isOpen={isDropdownOpen} />
      </WorkspaceSwitcherComponents.Trigger>

      <WorkspaceSwitcherComponents.Content
        setIsDropdownOpen={setIsDropdownOpen}
        selectedWorkspace={selectedWorkspace}
      />
    </WorkspaceSwitcherComponents.Root>
  );
};

export default WorkspaceSwitcher;

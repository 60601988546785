import { useMemo, useCallback } from 'react';

import styled from 'styled-components';
import tw from 'twin.macro';
import moment from 'moment';

import { AngleLeft as LeftIcon, AngleRight as RightIcon } from '@benepass/icons';
import { fromDate } from 'utils/src/DateTime';
import { grayscale } from '@benepass/colors';

import SelectField from '../selectfield';

const monthOptions = [
  {
    label: 'January',
    value: '0',
  },
  {
    label: 'February',
    value: '1',
  },
  {
    label: 'March',
    value: '2',
  },
  {
    label: 'April',
    value: '3',
  },
  {
    label: 'May',
    value: '4',
  },
  {
    label: 'June',
    value: '5',
  },
  {
    label: 'July',
    value: '6',
  },
  {
    label: 'August',
    value: '7',
  },
  {
    label: 'September',
    value: '8',
  },
  {
    label: 'October',
    value: '9',
  },
  {
    label: 'November',
    value: '10',
  },
  {
    label: 'December',
    value: '11',
  },
];

const StyledContainer = styled.div`
  ${tw`flex flex-col w-full pr-4 flex-grow pl-4`}
`;
const StyledHandleContainer = styled.div`
  ${tw`flex flex-grow justify-between gap-2 mb-4 -mt-3 h-12 items-center`}
`;
const StyledButton = styled.button`
  ${tw`w-6 h-6 rounded flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed`}
`;

type Props = {
  date: Date | undefined;
  onChange: (date: Date) => void;
  maxDate?: Date;
  minDate?: Date;
};

const DateRangePickerNavbar = ({ date, onChange, maxDate, minDate }: Props): JSX.Element => {
  const maxDateEndOfMonth = useMemo(() => {
    if (!maxDate) return null;
    return fromDate(maxDate).endOf('month');
  }, [maxDate]);

  const momentDate = useMemo(() => fromDate(date), [date]);

  const minDateStartOfMonth = useMemo(() => {
    if (!minDate) return null;
    return fromDate(minDate).startOf('month');
  }, [minDate]);

  const isNextMonthDisabled = useMemo(() => {
    const nextMonth = momentDate.clone().add(1, 'month');

    if (maxDateEndOfMonth && nextMonth.isSameOrAfter(maxDateEndOfMonth)) {
      return true;
    }

    return false;
  }, [momentDate, maxDateEndOfMonth]);

  const isPreviousMonthDisabled = useMemo(() => {
    const previousMonth = momentDate.clone().subtract(1, 'month');

    if (minDateStartOfMonth && previousMonth.isSameOrBefore(minDateStartOfMonth)) {
      return true;
    }

    return false;
  }, [momentDate, minDateStartOfMonth]);

  const nextMonth = useCallback(() => {
    const newDate = momentDate.clone().add(1, 'month');

    if (maxDateEndOfMonth && newDate.isSameOrAfter(maxDateEndOfMonth, 'm')) {
      return;
    }

    onChange(newDate.toDate());
  }, [momentDate, onChange, maxDateEndOfMonth]);

  const previousMonth = useCallback(() => {
    onChange(momentDate.clone().subtract(1, 'month').toDate());
  }, [momentDate, onChange]);

  const [readableMonth, readableYear] = useMemo(() => {
    return [momentDate.format('MMMM'), momentDate.format('YYYY')];
  }, [momentDate]);

  const yearOptions = useMemo(() => {
    const minYear = minDateStartOfMonth?.year() ?? 1920;
    const maxYear = maxDateEndOfMonth?.year() ?? moment().year() + 15;

    return Array.from({ length: maxYear + 1 - minYear }, (_, i) => {
      const v = (i + minYear).toString();
      return {
        value: v,
        label: v,
      };
    });
  }, [maxDateEndOfMonth, minDateStartOfMonth]);

  const handleYearSelect = useCallback(
    (year: string) => {
      const newDate = momentDate.clone().year(Number.parseInt(year, 10));
      if (maxDateEndOfMonth && newDate.isAfter(maxDateEndOfMonth)) {
        return;
      }

      onChange(newDate.toDate());
    },
    [maxDateEndOfMonth, momentDate, onChange]
  );

  const handleMonthSelect = useCallback(
    (month: string) => {
      const newDate = momentDate.clone().month(Number.parseInt(month, 10));
      if (maxDateEndOfMonth && newDate.isAfter(maxDateEndOfMonth)) {
        return;
      }

      onChange(newDate.toDate());
    },
    [maxDateEndOfMonth, momentDate, onChange]
  );

  return (
    <StyledContainer>
      <StyledHandleContainer>
        <StyledButton onClick={previousMonth} disabled={isPreviousMonthDisabled}>
          <LeftIcon color={grayscale[24]} />
        </StyledButton>

        <span>
          {readableMonth},&nbsp;
          <span className="font-medium">{readableYear}</span>
        </span>

        <StyledButton onClick={nextMonth} disabled={isNextMonthDisabled}>
          <RightIcon color={grayscale[24]} />
        </StyledButton>
      </StyledHandleContainer>

      <StyledHandleContainer>
        <div className="flex flex-row justify-stretch w-full gap-3">
          <div style={{ flexBasis: '60%' }}>
            <SelectField
              size="sm"
              options={monthOptions.filter((option) => {
                const constructedDate = momentDate.clone().month(Number.parseInt(option.value, 10));

                if (!minDateStartOfMonth && !maxDateEndOfMonth) {
                  return true;
                }

                if (minDateStartOfMonth && !maxDateEndOfMonth) {
                  return constructedDate.isSameOrAfter(minDateStartOfMonth);
                }

                if (!minDateStartOfMonth && maxDateEndOfMonth) {
                  return constructedDate.isSameOrBefore(maxDateEndOfMonth);
                }

                return constructedDate.isBetween(minDateStartOfMonth, maxDateEndOfMonth);
              })}
              value={momentDate.month().toString()}
              onValueChange={handleMonthSelect}
            />
          </div>
          <div className="flex-grow">
            <SelectField size="sm" options={yearOptions} value={readableYear} onValueChange={handleYearSelect} />
          </div>
        </div>
      </StyledHandleContainer>
    </StyledContainer>
  );
};

export default DateRangePickerNavbar;

import React from 'react';
import * as PrimitiveSelect from '@radix-ui/react-select';
import Text from '../text';

import { AngleDown as AngleDownIcon, AngleUp as AngleUpIcon } from '@benepass/icons';

import type { SelectItemProps } from '@radix-ui/react-select';
import { grayscale } from '@benepass/colors';
import customTwMerge from '../../utils/twMerge';

export type Option = {
  label: string;
  value: string;
  props?: SelectItemProps;
};

type Props = {
  size?: 'sm' | 'md';
  options: Option[];
  value: string;
  onValueChange?: (value: string) => void;
  disabled?: boolean;
  className?: string;
};

const SelectField = ({ options, value, size = 'md', disabled, onValueChange, className }: Props) => {
  const defaultValue = React.useMemo(() => {
    const isValueInProps = options.some(({ value: v }) => v === value);
    if (isValueInProps && value) return value;

    return options?.[0]?.value;
  }, [value, options]);

  React.useEffect(() => {
    if (defaultValue && defaultValue !== value) {
      onValueChange?.(defaultValue);
    }
  }, [defaultValue, value, onValueChange]);

  return (
    <div>
      <PrimitiveSelect.Root defaultValue={defaultValue} value={defaultValue} onValueChange={onValueChange}>
        <PrimitiveSelect.Trigger
          className={customTwMerge(
            `border border-grayscale-16 rounded-lg w-full flex flex-row px-4 py-2 items-center overflow-hidden
            disabled:bg-grayscale-2 disabled:cursor-not-allowed disabled:text-grayscale-64`,
            className
          )}
          disabled={(disabled as boolean) || false}
        >
          <Text as="span" size="md" className="text-grayscale-80 overflow-hidden break-words text-start">
            <PrimitiveSelect.Value />
          </Text>

          <PrimitiveSelect.Icon className="ml-auto">
            <AngleDownIcon color={grayscale[48]} size={size === 'sm' ? '16px' : '24px'} />
          </PrimitiveSelect.Icon>
        </PrimitiveSelect.Trigger>

        <PrimitiveSelect.Content className="overflow-hidden bg-white shadow-3dp">
          <PrimitiveSelect.ScrollUpButton className="py-2 flex justify-center">
            <AngleUpIcon />
          </PrimitiveSelect.ScrollUpButton>

          <PrimitiveSelect.Viewport>
            <PrimitiveSelect.Group>
              {options.map((option) => (
                <PrimitiveSelect.Item
                  {...option.props}
                  className="px-6 py-3 outline-none hover:bg-gray-100 focus:bg-gray-100 cursor-pointer"
                  value={option.value}
                  key={option.value}
                >
                  <PrimitiveSelect.ItemText>{option.label}</PrimitiveSelect.ItemText>
                </PrimitiveSelect.Item>
              ))}
            </PrimitiveSelect.Group>
          </PrimitiveSelect.Viewport>

          <PrimitiveSelect.ScrollDownButton className="py-2 flex justify-center">
            <AngleDownIcon />
          </PrimitiveSelect.ScrollDownButton>
        </PrimitiveSelect.Content>
      </PrimitiveSelect.Root>
    </div>
  );
};

export default SelectField;

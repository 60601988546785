const pages = {
  index: '/settings',
  all: '/settings/(.*)?',
  editPersonalEmail: '/settings/edit/personal-email',
  validateInformation: '/settings/edit/information',
  editPhoneNumber: '/settings/edit/phone-number',
  documents: '/documents',
  documentsForms: '/documents/forms',
  documentsHsaStatements: '/documents/hsa-statements',
};

export default pages;

import React from 'react';

import { useUserWorkspacesQuery } from '@app/data/user-workspaces.query';
import { useUserWorkspace } from '@hooks/use-user-workspace';

import Root from './workspace-switcher-root';
import Trigger from './workspace-switcher-trigger';
import Content from './workspace-switcher-content';

type Props = React.PropsWithChildren;

const WorkspaceSwitcher = ({ children }: Props) => {
  const workspaces = useUserWorkspacesQuery();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);
  const [userWorkspace] = useUserWorkspace();

  const selectedWorkspace = React.useMemo(
    () => workspaces.find((workspace) => userWorkspace === workspace.apiID),
    [workspaces, userWorkspace]
  );

  if (!selectedWorkspace || (workspaces.length || 0) <= 1) {
    return <>{children || null}</>;
  }

  return (
    <Root isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen}>
      <Trigger selectedWorkspace={selectedWorkspace} />
      <Content setIsDropdownOpen={setIsDropdownOpen} selectedWorkspace={selectedWorkspace} />
    </Root>
  );
};

const Wrapper = ({ ...props }: Props) => (
  <React.Suspense fallback={<div className="w-10 h-10 rounded-xl bg-indigo-90 animate-pulse" />}>
    <WorkspaceSwitcher {...props} />
  </React.Suspense>
);

export { Trigger, Content, Root };

export { default as Dropdown } from './workspace-selection-dropdown';

export default Wrapper;

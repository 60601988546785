import { graphql } from 'relay-runtime';
import { useLazyLoadQuery } from 'react-relay';

import type { viewerQuery as Query } from './__generated__/viewerQuery.graphql';

const query = graphql`
  query viewerQuery($includeHasHSA: Boolean = false) {
    viewer {
      apiID
      benepassCashAccount
      canLinkBankAccount
      canViewBPCash
      canSubmitReimbursement
      email
      firstName
      fullName
      nickname
      hasHSA @include(if: $includeHasHSA)
      canEnableOverages
      overagesAchDebitEnabled
    }
  }
`;

export const useViewerQuery = ({ includeHasHSA }: { includeHasHSA?: boolean } = {}) => {
  const { viewer } = useLazyLoadQuery<Query>(query, { includeHasHSA }, { fetchPolicy: 'store-or-network' });
  return viewer;
};
